// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-instruction {
    font-size: 12px;
    margin-top: 16px;
}

.main-table {
    padding-top: 5px;
}

.table-row-width {
    width: 100%;
    display: flex;
}

.td-text-wrap {
    width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-hr-scroll {
    height: 50vh;
    overflow-y: auto;
}

.section-divider {
    border-right: 1px solid #6633CA;
}

.loading {
    height: 100px;
}


.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #212529;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}

.modal-height {
    height: 72%;
}

`, "",{"version":3,"sources":["webpack://./src/Security/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kDAAkD;IAClD,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".note-instruction {\n    font-size: 12px;\n    margin-top: 16px;\n}\n\n.main-table {\n    padding-top: 5px;\n}\n\n.table-row-width {\n    width: 100%;\n    display: flex;\n}\n\n.td-text-wrap {\n    width: 325px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.table-hr-scroll {\n    height: 50vh;\n    overflow-y: auto;\n}\n\n.section-divider {\n    border-right: 1px solid #6633CA;\n}\n\n.loading {\n    height: 100px;\n}\n\n\n.accordion-button:focus {\n    box-shadow: none;\n}\n\n.accordion-button:not(.collapsed) {\n    color: #212529;\n}\n\n.modal-body {\n    /* 100% = dialog height, 120px = header + footer */\n    max-height: calc(100% - 120px);\n    overflow-y: scroll;\n}\n\n.modal-height {\n    height: 72%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import './spinner.css';
import HashLoader from "react-spinners/HashLoader";

const Spinner = (props) => {
    const loader = props.spinnerLoad;
    return (
        <>
            {
                loader ? (
                    <div id="loading-wrapper" className="flex flex-col items-center justify-center">
                        <HashLoader color="#36d7b7" size={50} />
                        <span className='load-text'>Loading...</span>
                    </div>
                ) : (<></>)
            }
        </>
    )
}
export default Spinner;
import ConfigData from "./../config.json";
import axios from "axios";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useRolesContext } from "../shared/context/roles-context";
import { BiCopy, BiUpload, BiBulb } from "react-icons/bi";
import { IoPerson, IoLogOutOutline, IoThumbsDownSharp, IoThumbsUpSharp, IoSettingsOutline, IoHelpCircle, IoHelpCircleOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { FaTrash } from 'react-icons/fa';
import { BsChatLeftText } from 'react-icons/bs';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useMsal } from "@azure/msal-react";

// Model popup imports
import MultiplePromptModel from '../Component/multiple-prompt';
import AddPrompt from '../Component/Prompt/add-prompt';
import EditPrompt from "../Component/Prompt/edit-prompt";
import FileUpload from "../Component/Prompt/upload-file";
import RAGFileView from "../Component/rag-files-view";
import Security from '../Security';
//END

import HashLoader from "react-spinners/HashLoader";
import ClipboardJS from "clipboard";
import 'bootstrap/dist/css/bootstrap.css';
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toaster from "../shared/toaster";
import Spinner from "../shared/spinner";

//menu items
import Menu from "@mui/material/Menu"; 
import MenuItem from "@mui/material/MenuItem";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

const Chat = () => {
  const empid = sessionStorage.getItem('user_id');
  const user_type = sessionStorage.getItem('user_type');
  const user_consent = JSON.parse(sessionStorage.getItem('user_consent'));

 
  
  const [isUserConsent, setIsUserConsent] = useState(JSON.parse(sessionStorage.getItem('user_consent')));
  
 console.log("isUserConsent", isUserConsent);
  const roles = useRolesContext();
  // const roles = JSON.parse(sessionStorage.getItem('roles'));
  const ragObject = JSON.parse(sessionStorage.getItem('ragObject'));
  const [chat, setChat] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatTitle, setChatTitle] = useState({});
  const [input, setInput] = useState("");
  const [selected, setSelected] = useState("");
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupvisible, setPopupvisible] = useState(false);
  const [prompt_list, setprompt_list] = useState([]);
  const [std_prompt_list, setstd_prompt_list] = useState([]);
  const [files, setfiles] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [options, setOptions] = useState("GPT 3.5 Turbo");
  const [llm_list, setllm_list] = useState([]);
  const [model, setModel] = useState();
  const [editPrompt, setEditPrompt] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [isDisabledNewChatNtn, setIsDisabledNewChatNtn] = useState(false)
  const [toggle, setToggle] = useState(false);
  const [disabledToggle, setDisabledToggle] = useState(true);
  const chatMessageEndRef = useRef(null);
  const { instance, accounts } = useMsal();
  const [promptFlag, setPromptFlag] = useState(1);
  const [isFileProgress, setIsFileProgress] = useState(false);
  const multi_prompt_limit = ConfigData.MULTI_PROMPT_DISPLAY_LIMIT;
  const callEditModel = useRef(null);
  const callAddModel = useRef(null);
  const callUploadFileModel = useRef(null);
  const callMultiPromptModel = useRef(null);
  const callSecurityModel = useRef(null);

  const ragSessionObj = {
    toggle: false,
    topic_id: '',
    rag_id: 0,
    role_id: 0,
    role: ''
  };


  
  const [isChatBoxEnable, setIsChatBoxEnable] = useState(isUserConsent);
  const [count, setCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const handleSignOut = () => {
    instance.logoutRedirect();
    sessionStorage.clear();
  }

  const handleMenuClick = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  const addconversation = async (type) => {
    console.log("addconversation ");
    setChat([]);
    const freetext = document.getElementById('freetext');
    const dt = new Date().getTime();
    // alert(dt)
   

    if (type === 'onclick') {
     
      console.log("dt", dt);
      freetext.focus();
      const chat_list = {
        topic_desc: "New Chat",
        topic_id: dt,
        new_topic: true
      };

      const ch = chatHistory;
      // alway push in first position
      ch.unshift(chat_list);
      setChatHistory([...ch]);
      setChatTitle(chat_list)
    }
    setSelected(dt);
    setIsDisabledNewChatNtn(type === 'onclick' ? true : false);
  };

  const highlightSelectedGrp = (topic_id = undefined) => {
    if (!topic_id) {
      topic_id = selected
    }
    const selectedGroup = document.querySelectorAll('li');
    const selectedLI = document.getElementById(topic_id);
    if (selectedLI) {
      selectedGroup.forEach((element) => {
        element.classList.remove('selected-group');
      });
      selectedLI.classList.add('selected-group')
    }

  }

  const selectconveration = async (topic_id) => {
    console.log("topic_id in selectconveration ", topic_id);
    setPopup(false);
    setSelected((prevSelected) => topic_id);
    setChat([]);
    setPageLoader(true);
    let chat_list = [{}];
    //if(i && selected !== "New Chat"){
    const createTitle1 = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/topic/${topic_id}/conversation`, {})
      .then((createTitle1) => {
        chat_list = createTitle1.data;

        chat_list.map((item, index) => {
          const newChat = [
            { role: "user", content: item.request },
            { role: "assistant", content: item.response, topic_id: item.topic_id, request_id: item.request_id, feedback: item.feedback, attached_link: item.attached_link },
          ];
          setChat((prevChat) => [...prevChat, ...newChat]);
        });
        // Add chat title to display on page.
        setChatTitle(chatHistory.find(o => (o.topic_id == topic_id) && o.topic_desc));
        setPageLoader(false);
        highlightSelectedGrp(topic_id);
      })
      .catch((error) => {
        setPageLoader(false);
        Toaster({ type: 'error', message: error.message });
      });
    //  }
  };

  useEffect(() => {
    if (!!ragObject) {
      setDisabledToggle(!!ragObject.role_id ? false : true);
      setToggle(ragObject.toggle);
    }

    getconverationdata();
  }, []);

  // Automatically scroll down on latest chat conversation
  useEffect(() => {
    if (chatMessageEndRef.current) {
      chatMessageEndRef.current?.scrollIntoView();
    }
  }, [chat]);

  /*Show selected chat group*/
  useEffect(() => {
    highlightSelectedGrp();
  }, [chatHistory]);

  /**
 * Returns query response
 * @param  prompt_type 1 or 2 // 1 for userdrfined prompt and 2 for standard prompt
 *
 * @returns {object}  response object
 */
  const handleRagSend = () => {
    setLoader(true);
    setPageLoader(true);

    if (input.trim && checkedItems.length <= 0)
      setChat([...chat, { role: "user", content: input }]);
    let apires = null;
    const response = axios
      .post(process.env.REACT_APP_TALK_TO_DATA_URL, {
        topic_id: selected,
        user_id: empid,
        query: input,
        prompt_ids: checkedItems,
        llm_id: model,
        prompt_type: promptFlag,
        role_id: ragObject.role_id,
        role: ragObject.role,
        rag_id: ragObject.rag_id
      })
      .then((response) => {
        apires = response;
        setLoader(false);
        setPageLoader(false);
        setIsDisabledNewChatNtn(false);

        if (apires === null) {
          return
        }

        const resData = { ...apires.data.response[0] };

        if (resData?.errorMsg) {
          Toaster({ type: 'error', message: resData.errorMsg });
          return;
        }

        setChat([
          ...chat,
          { role: "user", content: resData.request ? resData.request : input },
          { role: "assistant", content: resData.response, topic_id: resData.topic_id, request_id: resData.request_id, attached_link: resData.attached_link },
        ]);
        setCheckedItems([]);
        getTopics();
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
        setLoader(false);
        setPageLoader(false);
        setIsDisabledNewChatNtn(false);
      });
    setInput("");
  }

  
  const handleSend = async () => {
    // Check user chat with uploaded file or not 
    console.log("handleSend ");
    validateChatGroup(handleRagSend, handleSendBtn);
    setInput("");
    setCount(0);
  };

  const validateChatGroup = async (ragCallback, callback, multiPrompts = undefined) => {
    const confirm_msg = ConfigData.MESSAGES.VALIDATE_GROUP_MSG;

    console.log("selected", selected);

    if (!!multiPrompts) {
      if (toggle) {
        if (ragObject.topic_id != selected) {
          if (window.confirm(confirm_msg) == true) {
            for (const promptLine of multiPrompts.slice(0, multi_prompt_limit)) {
              await ragCallback(promptLine.replaceAll('\\r\\n', '').replaceAll('\\n', '').replaceAll('\\,\\', '').replaceAll('\\', ''));
            }
          }
        } else {
          for (const promptLine of multiPrompts.slice(0, multi_prompt_limit)) {
            await ragCallback(promptLine.replaceAll('\\r\\n', '').replaceAll('\\n', '').replaceAll('\\,\\', '').replaceAll('\\', ''));
          }
        }
      } else {
        for (const promptLine of multiPrompts.slice(0, multi_prompt_limit)) {
          await callback(promptLine.replaceAll('\\r\\n', '').replaceAll('\\n', '').replaceAll('\\,\\', '').replaceAll('\\', ''));
        }
      }
      getTopics();
    } else {
      if (toggle) {
        if (ragObject.topic_id != selected) {
          if (window.confirm(confirm_msg) == true) {
            ragCallback();
          }
        } else {
          ragCallback();
        }
      } else {
        callback();
      }
    }
  }

  /**
  * Returns query response
  * @param  prompt_type 1 or 2 // 1 for userdrfined prompt and 2 for standard prompt
  *
  * @returns {object}  response object
  */
  const handleSendBtn = async () => {
    if (!selected) {
      const id = new Date().getTime();
      setSelected((prevSelected) => id);
    }

    if (input.trim && checkedItems.length <= 0)
      setChat([...chat, { role: "user", content: input }]);

    // if (input.trim && checkedItems.length > 0) {
    let apires = null;
    // setChat([...chat, { role: "user", content: input }]);
    setLoader(true);
    setPageLoader(true);
   

    const response = await axios
      .post(process.env.REACT_APP_CHAT_URL, {
        topic_id: selected,
        user_id: empid,
        query: input,
        prompt_ids: checkedItems,
        llm_id: model,
        prompt_type: promptFlag
      })
      .then((response) => {
        apires = response;
        setLoader(false);
        setPageLoader(false);

        //condition :: check for load topics only onces when create the New chat.
        if (chatHistory.find(o => o.new_topic === true)
          || !chatHistory.some(chat => chat.topic_id === selected.toString())
          || chatHistory.length <= 0) {
          getTopics();
        }
        setCheckedItems([]);

        setIsDisabledNewChatNtn(false);
        setPopup(true);

        if (apires === null) {
          return
        }

        const resData = { ...apires.data.response[0] };

        if (resData?.errorMsg) {
          Toaster({ type: 'error', message: resData.errorMsg });
          return;
        }

        setChat([
          ...chat,
          { role: "user", content: resData.request ? resData.request : input },
          {
            role: "assistant", content: !!apires ? resData.response : `Selected LLM model configuration not a valid model`,
            topic_id: resData.topic_id, request_id: resData.request_id
          },
        ]);
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
        setLoader(false);
        setPageLoader(false);
        setIsDisabledNewChatNtn(false);
      });

    if (!!apires && apires.data.Flag === 1) {
      await getconverationdata();
    }
    setInput("");
    // }
  }

  const handleFeedback = (fb, chatDetails) => {
    const thumbsUp = document.getElementById('thumbsUp' + chatDetails.request_id);
    const thumbsDown = document.getElementById('thumbsDown' + chatDetails.request_id);

    if (chatDetails.feedback != fb) {
      setPageLoader(true);
      if (fb == 1) {
        thumbsUp.style.color = '#F37822';
        thumbsDown.style.color = '#000';
      } else {
        thumbsDown.style.color = '#F37822';
        thumbsUp.style.color = '#000';
      }

      axios.put(process.env.REACT_APP_FEEDBACK_URL, {
        topic_id: chatDetails.topic_id,
        request_id: chatDetails.request_id,
        feedback: fb
      })
        .then((response) => {
          setPageLoader(false);
          Toaster({ type: 'success', message: 'Feedback Submitted' });
        })
        .catch((error) => {
          setPageLoader(false);
          console.error('ERROR ', error);
          Toaster({ type: 'error', message: error.message });
        });
    } else {
      // alert('No change detected');
    }

  }

  const handleCopy = () => {
    Toaster({ type: 'info', message: `Copied to clipboard` });
    const clipboard = new ClipboardJS(".copy-button");
    clipboard.on("success", () => {
      console.log("Copied to clipboard!", clipboard);
      // Toaster({ type: 'info', message: `Copied to clipboard` });
    });
    clipboard.on("error", () => {
      console.log("Failed to copy to clipboard!");
      // Toaster({ type: 'error', message: error });
    });
  };

  const renderoutput = (input) => {
    const parts = input.content.split("```");
    return (
      <div>
        {/* Copy clipboard option for response chat */}
        {input.role != 'user' ? (
          <div className="chat-options">
            {/* chat options (Like, Dislike and copy clipboard) */}
            <ul className="feedback-opt">
              <li>
                <IoThumbsUpSharp id={'thumbsUp' + input.request_id} size={20} className="feedback" color={input.feedback === "1" ? '#F37822' : '#000'} onClick={() => handleFeedback(1, input)} />
              </li>
              <li>
                <IoThumbsDownSharp id={'thumbsDown' + input.request_id} size={20} className="feedback" color={input.feedback === "2" ? '#F37822' : '#000'} onClick={() => handleFeedback(2, input)} />
              </li>
              <li>
                <button
                  className="copy-button"
                  data-clipboard-text={input.content.substring(
                    input.length
                  )}
                  onClick={handleCopy}>
                  <BiCopy size={23} />
                </button>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        {/* Code snap not shown for user chat */}
        {parts.map((item, index) =>
          (index % 2 === 1 && input.role != 'user') ? (
            <div className="bg-black rounded-lg  ">
              <div className="flex justify-between items-center mb-4 bg-[#41414f] rounded-t-lg p-2">
                <h3 className="text-white text-lg font-bold mr-2  ">
                  {item.substring(0, item.indexOf("\n")) ? (
                    <div>{item.substring(0, item.indexOf("\n"))}</div>
                  ) : (
                    <div>code snippet</div>
                  )}
                </h3>
                <button
                  className="copy-button"
                  data-clipboard-text={item.substring(
                    item.indexOf("\n"),
                    item.length
                  )}
                  onClick={handleCopy}>
                  <BiCopy size={23} color="#ffffff" />
                </button>
              </div>
              <div className="rounded-lg p-2 ">
                <SyntaxHighlighter
                  language={
                    item.substring(0, item.indexOf("\n"))
                      ? item.substring(0, item.indexOf("\n"))
                      : "jsx"
                  }
                  style={atomDark}
                >
                  {item.substring(item.indexOf("\n"), item.length).trim()}
                </SyntaxHighlighter>
              </div>
            </div>
          ) : (
            <>
              <div className="format-html" key={index}>{item}</div>
              {
                (input?.attached_link && input.role != 'user') ? (
                  Array.from(Object.entries(input?.attached_link)).map((item, i) => {
                    return (
                      <span>
                        <a href={item[1]} target="_blank" rel="noopener noreferrer">
                          {item[0]}
                        </a>
                        <br></br>
                      </span>
                    )
                  })
                ) : (null)
              }
            </>
          )
        )
        }
      </div>
    );
  };

  const handlecheck2 = (event) => {
    console.log("handle terms n conditions");
    const item = event.target.value;
    const isChecked = event.target.checked;
    console.log("isChecked", isChecked);

    
   setIsUserConsent(JSON.parse(sessionStorage.getItem('user_consent')));
     
    if (isUserConsent == true) {
      setIsChatBoxEnable(true);
    }
    else
    {
      if (isChecked === true) {
          //put call
                let url = process.env.REACT_APP_UPDATE_USER;
                console.log("update consent url ",url);
                const response = axios
                      .put(url, {
                          user_id: empid,
                          user_consent: isChecked           
                      })
                      .then((response) => {
                        Toaster({ type: 'success', message: 'User consent updated successfully' });
                        setIsChatBoxEnable(true);                     
                        sessionStorage.setItem("user_consent", isChecked);
                        setIsUserConsent(isChecked);
                        console.log("userconset after ",sessionStorage.getItem('user_consent'));
                      })
                      .catch((error) => {
                          //setPageLoader(false);
                          console.error('ERROR ', error.message);
                        Toaster({ type: 'error', message: error.message });
                        setIsChatBoxEnable(false);
                        
                      });   
        

              }
      }


    
  


  };
  const handlecheck1 = (event) => {
    const item = event.target.value;
    const isChecked = event.target.checked;
    setCheckedItems((prevItems) =>
      isChecked ? [...prevItems, item] : prevItems.filter((i) => i !== item)
    );
  };

  const handleKeyDown_Old = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setInput(input + "\n");
    } else if (e.key === "Enter" && e.shiftKey) {
      //e.preventDefault();
      const c = input.trim() ? handleSend() : undefined;
    }
  };

  const handleKeyDown = (e) => {
    
     if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInput(input + "\n");
    }
    else if (e.key === "Enter") {
      
       if (count > ConfigData.DEFAULT_CHAT_CHARACTER_LIMIT) {
         e.preventDefault();
       }
       else
       {
         const c = input.trim() ? handleSend() : undefined;
         setInput("");
         setCount(0);
       }
      
      
    }
  };


  // const inputsHandler = (e) => {
  //   //alert(e.target.value);
  //  // if (e.target.name === "prompt_input") {
  //     setCount(e.target.value.trim().length);

  //   //}
  //   setInput(e.target.value);
  //  // setInputField({ ...inputField, [e.target.value]: e.target.value });
  // }

  const inputsHandler = (e) => {
    setCount(e.target.value.trim().length) 
    setInput(e.target.value); 
  }

  const handledelete_history = async (topic_id, topic_desc) => {
    if (window.confirm("Do you want to continue?") == true) {
      const createTitle1 = await axios.put(process.env.REACT_APP_UPDATE_TOPIC, {
        user_id: empid,
        topic_id: topic_id,
      })
        .then((response) => {
          // setChatHistory([]);
          setSelected();

          if (topic_id != selected)
            selectconveration(selected);

          getTopics();
          // getconverationdata();
          Toaster({ type: 'success', message: `${topic_desc} Chat Deleted Successfully` });
        })
        .catch((error) => {
          console.error('ERROR ', error);
          Toaster({ type: 'error', message: error.message });
        });
    }
  };

  // Read uploaded csv and text file for multiple prompt functionality
  const readJsonFile = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = event => {
        if (event.target) {
          resolve(JSON.stringify(event.target.result).replaceAll('"', ''))
        }
      }

      fileReader.onerror = error => reject(error)
      fileReader.readAsText(file)
    })
 
  // Call when new prompt added  
  const handleNewPromptModelChange = (prompt_type) => {
    (prompt_type === 1)
      ? fetchUserDefinePrompt()
      : fetchStandardPrompt()
  }

  const handleFileModelChange = (fileModelEvent) => {
    handleFileChange(fileModelEvent);
  }

  // Multiple prompt upload functionality
  const handleFileChange = async (event) => {
    let parsedData = "";
    setfiles(event.target.files[0]);

    if (event.target.files[0]) {
      parsedData = await readJsonFile(event.target.files[0])
    }
    const multiPrompts = parsedData.split(';');
    validateChatGroup(uploadMultiplePromptForRAG, uploadMultiplePrompt, multiPrompts);
    setIsDisabledNewChatNtn(false);
    setCheckedItems([]);
  };

  // Async calling function
  const uploadMultiplePrompt = (promptText) => {
    let apires = "";
    setLoader(true);
    setPageLoader(true);
    return new Promise((resolve) => {
      const response = axios.post(process.env.REACT_APP_CHAT_URL, {
        topic_id: selected,
        user_id: empid,
        query: promptText,
        prompt_ids: checkedItems,
        llm_id: model, // pass the response value as the prompt parameter
        prompt_type: promptFlag
      })
        .then((response) => {
          apires = response;
          setLoader(false);
          setPageLoader(false);
          if (apires.data.response.length <= 0) {
            return
          }

          const resData = { ...apires.data.response[0] };
          if (resData?.errorMsg) {
            Toaster({ type: 'error', message: resData.errorMsg });
            return;
          }

          const newChat = [
            { role: "user", content: promptText },
            {
              role: "assistant", content: !!apires ? resData?.response : `Selected LLM model configuration not a valid model`,
              topic_id: resData?.topic_id, request_id: resData?.request_id
            }
          ];

          resolve(response);
          setChat((prevChat) => [...prevChat, ...newChat]);
        })
        .catch((error) => {
          setLoader(false);
          setPageLoader(false);
          setIsDisabledNewChatNtn(false);
          console.error('ERROR ', error);
          Toaster({ type: 'error', message: error.message });
        });
    })
  }

  // Async calling function for RagResponse
  const uploadMultiplePromptForRAG = (promptText) => {
    let apires = "";
    setLoader(true);
    setPageLoader(true);
    return new Promise((resolve) => {
      const response = axios.post(process.env.REACT_APP_TALK_TO_DATA_URL, {
        topic_id: selected,
        user_id: empid,
        query: promptText,
        prompt_ids: checkedItems,
        llm_id: model,
        prompt_type: promptFlag,
        role_id: ragObject.role_id,
        role: ragObject.role,
        rag_id: ragObject.rag_id
      })
        .then((response) => {
          apires = response;
          setLoader(false);
          setPageLoader(false);

          if (apires.data.response.length <= 0) {
            return
          }

          const resData = { ...apires.data.response[0] };
          if (resData?.errorMsg) {
            Toaster({ type: 'error', message: resData.errorMsg });
            return;
          }

          const newChat = [
            { role: "user", content: promptText },
            { role: "assistant", content: resData.response, topic_id: resData.topic_id, request_id: resData.request_id, attached_link: resData.attached_link },
          ];

          resolve(response);
          setChat((prevChat) => [...prevChat, ...newChat]);
        })
        .catch((error) => {
          setLoader(false);
          setPageLoader(false);
          setIsDisabledNewChatNtn(false);
          console.error('ERROR ', error);
          Toaster({ type: 'error', message: error.message });
        });
    })
  }

  const getTopics = async () => {

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/${empid}/topic`, {})
      .then((response) => {
        const topic_list = response.data;
        setChatHistory(topic_list.reverse());
        setChatTitle(topic_list.find(o => (o.topic_id == selected) && o.topic_desc));
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
      });
  }

  const clearFreeText = () => {
    setInput("");
    setCount(0);
  }

  const handleToggleChange = (e) => {
    setToggle(e.target.checked);
    ragObject.toggle = e.target.checked;
    sessionStorage.setItem('ragObject', JSON.stringify(ragObject));
  }

  const fetchStandardPrompt = async () => {
    const stdPrompts = await axios
      .get(process.env.REACT_APP_FETCH_STANDARD_PROMPT_URL, {})
      .then((response) => {
        const stdPrompt_list = response.data;
        setstd_prompt_list([]);
        setstd_prompt_list(stdPrompt_list.reverse());
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
      });
  }

  const fetchUserDefinePrompt = async () => {
    const prompts = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/user/${empid}/prompt`, {})
      .then((response) => {
        const prompt_list = response.data;
        setprompt_list([]);
        setprompt_list(prompt_list.reverse());
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
      });
  }

  const handleSelectedNode = (selectedNode) => {
    // Clear file upload old session values
    sessionStorage.removeItem("ragObject");
    const selectedChatId = new Date().getTime();

    if (selectedNode.role_id === 0 || (selectedNode.role_id === 2 && selectedNode.org_rag_id == "0")) {
      ragSessionObj.toggle = false;
      ragSessionObj.role_id = selectedNode.role_id;
      sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));
      setDisabledToggle(true);
      return setToggle(false);
    }

    // Create new file upload  session
    ragSessionObj.toggle = true;
    ragSessionObj.rag_id = (selectedNode.role_id === 2) ? selectedNode.org_rag_id : selectedNode.user_rag_id;
    ragSessionObj.topic_id = selectedChatId;
    ragSessionObj.role_id = selectedNode.role_id;
    ragSessionObj.role = (selectedNode.role_id === 2) ? 'Org' : 'User';
    sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));
    setSelected(selectedChatId);
    setToggle(true);
    setDisabledToggle(false);
    getconverationdata();
  }

  const handleAccoHeaderChange = (type) => {
    setCheckedItems([]);
  }

  const getconverationdata = async () => {
    setChat([]);
    getTopics();
    setIsDisabledNewChatNtn(false);
    fetchStandardPrompt();
    fetchUserDefinePrompt();

    const llm_lists = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/llm_list`, {})
      .then((response) => {
        const llm_list = response.data;
        setllm_list(llm_list);
        if (!sessionStorage.getItem('session_model')) {
          sessionStorage.setItem('session_model', llm_list[0].llm_id)
        }
        setModel(sessionStorage.getItem('session_model'));
        setOptions(sessionStorage.getItem('session_model'));
      })
      .catch((error) => {
        console.error('ERROR ', error);
        Toaster({ type: 'error', message: error.message });
      });
  };

  const handleDropChange = (event) => {
    setOptions(event.target.value);
    sessionStorage.setItem('session_model', event.target.value);
    setModel(event.target.value);
  }

  const handleFileCall = (filePregress) => {
    setIsFileProgress(filePregress);
  }

  return popupvisible ? (
    <div>
      <h1>
        this is for test
      </h1>
    </div>
  ) : (
    // <div className=" h-screen w-screen flex bg-[#050509] ">
    <>
      <div className="container-fluid main-wrapper row g-0">
        <div className="left-sidebar col-2 position-relative">
          <div className="logo-outer-box">
            <div className="logo-tangoe"></div>
          </div>
          <div className="">
            <div>{(!selected || selected === "") && addconversation('reload')}</div>
            <button
              className=" w-full new-chat-btn"
              onClick={() => addconversation('onclick')} //addconversation  handleSend_demo
              title="Add New Chat"
              disabled={isDisabledNewChatNtn}
            >
              {/* <span>
                <svg width="24"
                  height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7V17M7 12H17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
              </span> */}
              <span>New Chat</span>
            </button>
            </div>
           
          <div className="chat-chip-btn chat-chip-btn-wrapper chat-history-outer">
            {chatHistory.map((item, index) => (
              <ol>
                <li id={item.topic_id} className="chat-chip-hover">
                  <div className=" text-center  text-lg font-light flex items-center cursor-pointer justify-between">
                    <div className="flex flex-row" onClick={() => {
                      item.Topic != "New Chat" &&
                        selectconveration(item.topic_id);
                    }}>
                      <div className="m-2">
                        <BsChatLeftText />
                      </div>
                      <span className="chat-chip-label text-left" title={item.topic_desc}>{item.topic_desc?.replaceAll('"', '')}</span>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          handledelete_history(
                            item.topic_id,
                            item.topic_desc
                          )
                        } title="Delete">
                        <FaTrash color="#000" className="trash-btn" />
                      </button>
                    </div>
                  </div>
                </li>
              </ol>
            ))}
          </div>
        </div>
        <div className="chat-wrapper col-8 position-relative ">
          <div className="title-outer">
            <div>
              <label className="title-label title-text-wrap">{chatTitle && chatTitle['topic_desc']?.replaceAll('"', '')}</label>
            </div>
            {
              isFileProgress ? (
                <div className="in-progress-loader">
                  <HashLoader color="#20A65F" size={30} />
                  <strong>File(s) upload in-progress</strong>
                </div>
              ) : (<></>)
            }
            {
              (ConfigData.ROLES_BASE_ACCESS.VIEW_FOLDER_SELECTION.filter(item => roles.find(x => x === item)).length) ? (
                <div className="form-check form-switch toggle-opt">
                  <input className="form-check-input" type="checkbox" checked={toggle} disabled={disabledToggle}
                    role="switch" onClick={handleToggleChange} id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Chat with File</label>
                </div>
              ) : (null)
            }

          </div>
          <div id="chatSection" className=" h-[76%] overflow-auto  pt-8">
            {chat.length > 0 ? (
              <div>
                {chat.map((item, index) => (
                  <div
                    className={` w-[95%] mx-auto p-6 text-black flex ${item.role === "assistant" && "response-chat-wrapper rounded"
                      }`}
                  >
                    <span className=" mr-8 p-2 bg-slate-500 text-white rounded-full h-full ">
                      {item.role === "user" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-user-bolt"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4c.267 0 .529 .026 .781 .076"></path>
                          <path d="M19 16l-2 3h4l-2 3"></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-robot"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M7 7h10a2 2 0 0 1 2 2v1l1 1v3l-1 1v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2z"></path>
                          <path d="M10 16h4"></path>
                          <circle
                            cx="8.5"
                            cy="11.5"
                            r=".5"
                            fill="currentColor"
                          ></circle>
                          <circle
                            cx="15.5"
                            cy="11.5"
                            r=".5"
                            fill="currentColor"
                          ></circle>
                          <path d="M9 7l-1 -4"></path>
                          <path d="M15 7l1 -4"></path>
                        </svg>
                      )}
                    </span>
                    <div
                      className="w-[95%] leading-loose overflow-x-auto resp-chat"
                    >
                      {renderoutput(item)}
                    </div>
                  </div>
                ))}
                <div ref={chatMessageEndRef}></div>
              </div>
            ) : (
              <div className=" ">
                <div className="top-default-heading-wrapper">
                  <div className="dashboard-tangoe"></div>
                </div>
                <div className="justify-content-center d-flex d-none">
                  <div className=" flex flex-wrap justify-around max-w-[900px]">
                  </div>
                </div>
              </div>
            )}
            </div>
         
           
            <div className="title-checkbox"   hidden={isUserConsent}>
              <label>
                <input
                  type="checkbox"
                  // checked={checked}
                  onChange={handlecheck2}
                />{'  '}
                The user agreement process for Tangoe GPT has been updated. By checking this box, you confirm that you have read and agree to our 
                <a target="_blank" href="https://tangoe.sharepoint.com/sites/ProcessLibrary/GO%20Process%20Library/Forms/Category%20View.aspx?id=%2Fsites%2FProcessLibrary%2FGO%20Process%20Library%2FAcceptable%20Use%20Policy%2Epdf&parent=%2Fsites%2FProcessLibrary%2FGO%20Process%20Library"> Acceptable Use Policy </a>
               and<a target="_blank" href="https://google.com"> Privacy Policy</a>.
              </label>
                
            </div>
            <br></br>
              
           
            <div  className="" >
              <div disabled={!isChatBoxEnable}  className="chat-input-box-wrapper row">
              <div className="col-1 chat-input-msg-icon">
                {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path></svg>
               */}
                </div>
                <textarea disabled={!isChatBoxEnable}
                id="freetext"
                //value={input}
                  //onChange={(e) => setInput(e.target.value)}
                   
                  onChange={inputsHandler}
                  value={input}

                onKeyDown={handleKeyDown}
                className="chat-input-box col-9 rounded-lg pd-5 pr-16 hide-scroll-bar"
                placeholder="Type your message here..."
                />


                
                <div className="chat-input-box-limit col-9 rounded-lg pd-5 pr-16" >
                  <span  className={count <= ConfigData.DEFAULT_CHAT_CHARACTER_LIMIT ? 'text text-success' : 'text text-danger'}> Character limit  <strong>{count}/ {ConfigData.DEFAULT_CHAT_CHARACTER_LIMIT}</strong> </span>
                </div> 
                {/* <button className="clear-chat" type="button" onClick={clearFreeText} disabled={!isChatBoxEnable}>
                <i className="bi bi-search">x</i>
                </button> */}
                
                <div className="input-icon-wrapper d-flex col-2">
                 
                  <span
                    hidden={((count > ConfigData.DEFAULT_CHAT_CHARACTER_LIMIT)  || (!isChatBoxEnable ))? true : false}
                  className="cursor-pointer"
                  onClick={() => (input.trim() ? handleSend() : undefined)}
                  title="Send"
                >
                  {/* onClick={() => (input.trim() ? handleSend() : (checkedItems.length > 0)? handleSend() : undefined)} */}
                  {loader ? (
                    <div className="flex flex-col items-center justify-center">
                      <HashLoader color="#36d7b7" size={20} />
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-send"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10 14l11 -11"></path>
                      <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"></path>
                    </svg>
                  )}
                </span>
                {
                  (ConfigData.ROLES_BASE_ACCESS.UPLOAD_BUTTON.filter(item => roles.find(x => x === item)).length) ? (
                      <span className="cursor-pointer" onClick={() => callUploadFileModel.current()} title="Upload File"
                        hidden={!isChatBoxEnable ? true : false}>
                        <BiUpload size={30} />
                        
                        
                    </span>
                  ) : (null)
                }

                <FileUpload callUploadFileModel={callUploadFileModel} handleFileProgress={handleFileCall} />
              </div>
            </div>
          </div>
        </div>

        <div className=" right-sidebar col-2">
          <div className="profile-outer">
            <ul className="navbar-nav d-flex flex-row me-1">
              <li className="nav-item dropdown">
                <div className="profile-circle1">
                  <IoPerson size={25} color="#000" className="profile" title="Profile" />
                </div>
              </li>
                <li className="username" title={accounts[0]?.name}>
                {accounts[0]?.name}
                </li>
                <li className="profile">
                  <div onClick={handleOpenMenu}>
                    <IoHelpCircleOutline  size={25}  classname="setting-icon" color="#000"  title="Help"/>
                  </div>
                  <div >
                   <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
          >
         <MenuItem onClick={()=> window.open("https://tangoe.app.workramp.com/guides/88e345d0-bee2-11ee-ac10-0231b1eee8ed")}>Training</MenuItem>
        <MenuItem onClick={()=> window.open("https://tangoe.app.workramp.com/task_assignments/dd98edd8-bf81-11ee-8c3b-02e7ae2b609b")}>Terms & Privacy Policy</MenuItem>
       
        {/* <MenuItem onClick={handleClose}></MenuItem> */}
      </Menu>
                  </div>
                 
                </li>
              <li>
                <div onClick={handleSignOut} >
                  <IoLogOutOutline size={30} className="logout-icon" title="LogOut" />
                </div>
              </li>
            </ul>
          </div>
          <hr />
          <Tabs>
            <Tab eventKey="Prompt" title="Prompt">
              <Container>
                <Row>
                  <Col className="p-0">
                    <div>
                      <Accordion> {/*defaultActiveKey="0"*/}
                        <Accordion.Item className="mb-2" eventKey="0" >
                          <Accordion.Header onClick={() => handleAccoHeaderChange('admin')}>Standard Prompt</Accordion.Header>
                          <Accordion.Body className="standard-body-wrapper">
                            {
                              (ConfigData.ROLES_BASE_ACCESS.ADD_EDIT_STANDARD_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                <div className="add-prompt mb-3 ">
                                  <button
                                    className="w-full new-chat-btn" id="New_Prompt"
                                    title="Add New Prompt"
                                    onClick={() => (setPromptFlag(2), callAddModel.current())}
                                  >
                                    <span>New Prompt</span>
                                  </button>
                                </div>
                              ) : (null)
                            }
                            <div className="chat-chip-btn-wrapper">
                              {
                                (ConfigData.ROLES_BASE_ACCESS.VIEW_STANDARD_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                  std_prompt_list.map((item, index) => (
                                    <span className="chat-list-item">
                                      {/* <div className=" py-3 text-center rounded mt-4 text-lg font-light flex items-center px-8 hover:bg-slate-600  cursor-pointer justify-between"> */}
                                      <span className="prompt-title-hover text-center  text-lg font-light flex items-center justify-between">
                                        <span className="flex flex-row">
                                          <span className=" mr-4">
                                            <input
                                              type="checkbox"
                                              value={item.prompt_id}
                                              className="transform scale-150"
                                              onClick={() => setPromptFlag(2)}
                                              onChange={handlecheck1}
                                              checked={checkedItems.includes(item.prompt_id)}
                                            ></input>
                                          </span>
                                          <span className="chat-chip-label-right text-left" title={item.prompt_desc}>{item.prompt_title} </span>
                                        </span>
                                        <span>
                                          {
                                            (ConfigData.ROLES_BASE_ACCESS.ADD_EDIT_STANDARD_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                              <button onClick={() => (setEditPrompt(item), setPromptFlag(2), callEditModel.current())} title="Edit">
                                                < MdEdit />
                                              </button>
                                            ) : (<></>)
                                          }
                                        </span>
                                      </span>
                                    </span>
                                  ))
                                ) : (null)
                              }
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mb-2" eventKey="1" >
                          <Accordion.Header onClick={() => handleAccoHeaderChange('user')}>User Defined Prompts</Accordion.Header>
                          <Accordion.Body className="userdefined-body-wrapper">
                            <div>
                              <div className="add-prompt mb-3 ">
                                <span className="">
                                  {
                                    (ConfigData.ROLES_BASE_ACCESS.ADD_EDIT_USERDEFINED_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                      <button
                                        className="w-full new-chat-btn" id="New_Prompt"
                                        title="Add New Prompt"
                                        onClick={() => (setPromptFlag(1), callAddModel.current())}
                                      >
                                        <span>New Prompt</span>
                                      </button>
                                    ) : (null)
                                  }
                                </span>
                              </div>
                              {
                                (ConfigData.ROLES_BASE_ACCESS.VIEW_USERDEFINED_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                  <div className="chat-chip-btn-wrapper">
                                    {prompt_list.map((item, index) => (
                                      <span className="chat-list-item">
                                        {/* <div className=" py-3 text-center rounded mt-4 text-lg font-light flex items-center px-8 hover:bg-slate-600  cursor-pointer justify-between"> */}
                                        <span className="prompt-title-hover text-center  text-lg font-light flex items-center justify-between">
                                          <span className="flex flex-row">
                                            <span className=" mr-4">
                                              <input
                                                type="checkbox"
                                                value={item.prompt_id}
                                                className="transform scale-150"
                                                onClick={() => setPromptFlag(1)}
                                                onChange={handlecheck1}
                                                checked={checkedItems.includes(item.prompt_id)}
                                              ></input>
                                            </span>
                                            <span className="chat-chip-label-right text-left" title={item.prompt_desc}>{item.prompt_title} </span>
                                          </span>
                                          <span>
                                            {
                                              (ConfigData.ROLES_BASE_ACCESS.ADD_EDIT_USERDEFINED_PROMPT.filter(item => roles.find(x => x === item)).length) ? (
                                                <button onClick={() => (setEditPrompt(item), setPromptFlag(1), callEditModel.current())} title="Edit">
                                                  < MdEdit />
                                                </button>
                                              ) : (null)
                                            }
                                          </span>
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                ) : (null)
                              }

                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="Configuration" title="Configuration" className="configure-wrapper mx-2" >
              <div>
                <label className="ddl-label">Select Model</label>
                <select className="form-select" id="floatingSelect" value={options} onChange={handleDropChange} aria-label="Floating label select example" title="LLM Model">
                  {llm_list.map((item, index) => (
                    <option key={index} value={item.llm_id}>{item.llm_name}</option>
                  ))}
                </select>
              </div>
              <hr></hr>
              {
                (ConfigData.ROLES_BASE_ACCESS.VIEW_FOLDER_SELECTION.filter(item => roles.find(x => x === item)).length) ? (
                  <RAGFileView handleSelectionRAG={handleSelectedNode} />
                ) : (null)
              }

              {
                (ConfigData.ROLES_BASE_ACCESS.DJANGO_REDIRECT_URL_LINK.filter(item => roles.find(x => x === item)).length) ? (
                  <>
                    <hr></hr>
                    <div className="navidate-outer">
                      <a className="navigate-link" target="_blank" href={process.env.REACT_APP_ADMIN_PORTAL_URL} title="Redirect to admin portal">Redirect to admin portal</a>
                    </div>
                  </>
                ) : (null)
              }
              </Tab>
              
               <Tab eventKey="Application" title="Application">
                
                <hr></hr>
                    <div className="navidate-outer">
                      
                  <button variant="primary"  className=" w-full new-assessment-btn"
                    onClick={() => callSecurityModel.current()}>
                    Security Assessment</button>
                  {/* <button variant="primary"  className=" w-full new-assessment-btn"
                    onClick={() => callSecurityModel.current()}>
                    Finance</button>
                  <button variant="primary"  className=" w-full new-assessment-btn"
                    onClick={() => callSecurityModel.current()}>
                    Contracts</button> */}

                </div>
                  
               </Tab>

          </Tabs>
          <div >
            <button className="upload-multi-wrapper"
              onClick={() => callMultiPromptModel.current()}
              title="Upload Multi Prompt">
              Upload Multi Prompt
            </button>
            <MultiplePromptModel callMultiPromptModel={callMultiPromptModel} handleFileCallback={handleFileModelChange} />
          </div>
        </div>
      </div>
      <AddPrompt callAddModel={callAddModel} handleNewPromptCallback={handleNewPromptModelChange} promptFlag={promptFlag} />
      <EditPrompt callEditModel={callEditModel} handleNewPromptCallback={handleNewPromptModelChange} promptData={editPrompt} promptFlag={promptFlag} />
        <Spinner spinnerLoad={pageLoader} />
        <Security callSecurityModel={callSecurityModel} />
    </>
  );
};
export default Chat;
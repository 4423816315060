import "./login.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { RolesContext } from "../../shared/context/roles-context";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import Spinner from "../../shared/spinner";
import Chat from "../../Chat/index";

const Login = () => {
  const [login, setLogin] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const handleSignIn = () => {
    // instance.loginRedirect({ scopes: ["user.read"] });
    
    instance.loginRedirect({ scopes: ["user.read"] });
  };

  // export interface Roles [];

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      console.log('account.idToken',account.idToken);

      axios
        .post(process.env.REACT_APP_LOGIN_URL, { token: account.idToken })
        .then((response) => {
          if (response.status === 200) {
            
            setLoading(false);
            console.log("response",response);
            setUserRoles(response.data.roles);
            sessionStorage.setItem("user_id", response.data.user_id);
            sessionStorage.setItem("username", account.name);
            sessionStorage.setItem("email", account.username);
            sessionStorage.setItem("user_type", response.data.user_type);
            sessionStorage.setItem("user_consent", response.data.user_consent);
            
            
            setLogin(true);
          } else {
            sessionStorage.clear();
            alert("Please register yourself!");
            console.error("User authentication failed");
          }
        })
        .catch((error) => {
          sessionStorage.clear();
          alert("Please register yourself!");
          setLoading(false);
          console.log("Error occurred while authenticating user:", error);
        });
    } else {
      console.error("User authentication failed");
    }
  }, [isAuthenticated]);

  if (isAuthenticated && login) {
    return <>
      <RolesContext.Provider value={userRoles}>
        <Chat />
      </RolesContext.Provider>
    </>;
  }


  return (
    <div className="main">
      <div className="tangoe-logo"></div>
      <div>
        <div className="sso-outerbox">
          <div className="sso-box">
            <div className="title">Tangoe<strong className="ai">GPT</strong></div>
            <button
              onClick={handleSignIn}
              className="sso-btn w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-slate-800  rounded-md hover:bg-slate-500  focus:outline-none "
            >
              Single Sign-on
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
import ConfigData from './../../config.json';
import React, { useState } from "react";
import { FaTrash, FaRegFilePdf, FaFileWord, FaFile, FaSquare, FaFolder, FaFolderOpen, FaCheckSquare, FaMinusSquare,FaDownload } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { useRolesContext } from "./../context/roles-context";
import "./styles.css";
import axios from "axios";
import { saveAs } from 'file-saver';
import Toaster from '../toaster'




const DirectoryTreeView = (props) => {
  const data = flattenTree(props.folder);
  const roles = useRolesContext();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRd, setSelectedRd] = useState(props.selected);

  const handleCustSelect = (nodeId) => {
    props.handleSelect(nodeId);
  }

  const deleteTreeNode = (node) => {
    props.handleDelete(node);
  }

  const handleDownload = (treeNode) => {
    console.log("treeNode", treeNode);
    const formData = new FormData();
    formData.append('fileName', treeNode.name);
      console.log("formData", formData);
      var tmpFolderName = "";
      if (treeNode.parent = '1')
      {
        tmpFolderName = 'draft';
      }
      else if (treeNode.parent = '2')
      {
        tmpFolderName = 'response';
      }
      else if (treeNode.parent = '3')
      {
        tmpFolderName = 'final';
      }

    axios
      .post(process.env.REACT_APP_DOWNLOAD_FROM_FOLDER_URL, { 'fileName': treeNode.name, 'folder_name':tmpFolderName }, { responseType: 'arraybuffer' })   
      .then((response) => {
        console.log("response", response);
         const file = new Blob([response.data], {
        type: "application/octet-stream",
      });
        saveAs(file, treeNode.name);
         Toaster({ type: "success", message: `${treeNode.name} File downloaded successfully` });
    });
  }
   
  const downloadFileTreeNode = (node) => {
    handleDownload(node);
  }

  const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
      case "all":
        return <FaCheckSquare {...rest} />;
      case "none":
        return <FaSquare {...rest} />;
      case "some":
        return <FaMinusSquare {...rest} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {
        (props.treeViewType === 'folders') ? (
          <div className="directory">
            <TreeView
              data={data}
              aria-label="directory tree"
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                getNodeProps,
                level,
              }) => (
                <>
                  <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                    {isBranch ? (
                      <FolderIcon isOpen={isExpanded} size={20} />
                    ) : (
                      (element.parent != 0) ? (
                        <FileIcon filename={element.name} />
                      ) : (null)

                    )}

                    {
                      (element.children.length > 0 && element.parent === 0) ? (
                        <>
                          <div className="directory-title">{element.name} </div>
                          {/* <FaTrash color="gray" size={12} title="Delete" onClick={() => deleteTreeNode(element)} /> */}
                        </>
                      ) : (element.parent != 0) ? (
                        <>
                          <div className="directory-title">{element.name} </div>
                          {
                            (ConfigData.ROLES_BASE_ACCESS.ORG_FILE_UPLOAD_TAB_AND_BUTTON.filter(item => roles.find(x => x === item)).length) ? (
                                <FaTrash className="del-btn" color="#000" size={12} title="Delete" onClick={() => deleteTreeNode(element)} />
                              //  &&
                                //<FaDownload className="del-btn" color="#000" size={12} title="Download"  onClick={() => handleDownload(element)}  />
                            ) : (null)
                          }
                        </>
                      ) : (null)
                    }
                  </div>
                </>
              )}
            />
          </div>
        ) : (props.treeViewType === 'checkbox') ? (
          <div className="checkbox">
            <TreeView
              data={data}
              aria-label="Checkbox tree"
              multiSelect
              selectedIds={selectedIds}
              defaultExpandedIds={[1]}
              propagateSelect
              propagateSelectUpwards
              togglableSelect
              onSelect={(props) => props}
              onNodeSelect={(props) => handleCustSelect(props)}
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isSelected,
                isHalfSelected,
                getNodeProps,
                level,
                handleSelect,
                handleExpand,
              }) => {
                return (
                  <div
                    {...getNodeProps({ onClick: handleExpand })}
                    style={{ marginLeft: 40 * (level - 1) }}
                  >

                    {/* {isBranch && <ArrowIcon isOpen={isExpanded} />} */}
                    {
                      (props.showCheckbox === "level1") ? (
                        (level === 1) ? (
                          (element.children.length > 0 && element.parent === 0) ? (
                            <>
                              <input type="radio"
                                className="radio-btn"
                                value={element.id}
                                checked={selectedRd === element.id}
                                onChange={() => setSelectedRd(element.id)}
                                name="node"
                                onClick={(e) => {
                                  handleSelect(e);
                                  e.stopPropagation();
                                }}
                              />
                            </>
                          ) : (null)
                        ) : (null)
                      ) : (
                        <CheckBoxIcon
                          className="checkbox-icon"
                          onClick={(e) => {
                            handleSelect(e);
                            e.stopPropagation();
                          }}
                          variant={
                            isHalfSelected ? "some" : isSelected ? "all" : "none"
                          }
                        />
                      )
                    }
                    {isBranch ? (
                      <FolderIcon isOpen={isExpanded} size={20} />
                    ) : (
                      (element.parent != 0) ? (
                        <FileIcon filename={element.name} />
                      ) : (null)

                    )}
                    {
                      (element.children.length > 0 && element.parent === 0) ? (
                        <span className="name">{element.name}</span>
                      ) : (element.parent != 0) ? (
                        <span className="name">{element.name}</span>
                      ) : (null)
                    }
                  </div>
                );
              }}
            />
          </div>
        ) : (
          <></>
        )
      }

    </div>
  );
}

const FolderIcon = ({ isOpen }) =>
  isOpen ? (
    <FaFolderOpen color="#FFEA00" className="icon" fill="#FFEA00" size={25} />
  ) : (
    <FaFolder color="#FEBE10" className="icon" fill="#FEBE10" size={25} />
  );

const FileIcon = ({ filename }) => {
  const extension = filename.slice(filename.lastIndexOf(".") + 1);
  switch (extension) {
    case "pdf":
      return <FaRegFilePdf color="#FF0000" className="icon" size={20} />;
    case "doc":
      return <FaFileWord color="#01A6F0" className="icon" size={20} />;
    case "docx":
      return <FaFileWord color="#01A6F0" className="icon" size={20} />;
    // case "txt":
    //   return <FaFile color="green" className="icon" />;
    // case "npmignore":
    //   return <DiNpm color="red" className="icon" />;
    default:
      return <FaFile color="#D5CE58" className="icon" size={20} />;
  }
};

export default DirectoryTreeView;
import ConfigData from '../../../config.json';
import './styles.css';
import React, { useEffect, useState } from "react";
import { useRolesContext } from '../../../shared/context/roles-context';
import { Modal } from 'react-bootstrap';
import { Tabs, Tab } from "react-bootstrap";
import UserUpload from './user-upload-file';
import AdminUpload from './admin-upload-file';

const FileUpload = (props) => {
    const roles = useRolesContext();
    const [isShow, invokeModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        props.callUploadFileModel.current = handleModel;
    }, []);

    const handleModel = (falg = true) => {
        setIsDisabled(falg);
        return invokeModal(falg);
    }

    const handleOrgFileProgress = (filePregress) => {
        props.handleFileProgress(filePregress);
    }

    return (
        <Modal className='modal-lg' show={isShow}>
            <Modal.Header>
                <Modal.Title>Upload File</Modal.Title>
                <button type="button"
                    className="btn-close"
                    onClick={() => handleModel(false)}
                    title="Close"
                    aria-label="Close">
                </button>
            </Modal.Header>
            <Modal.Body>
                <Tabs>
                    {
                        (ConfigData.ROLES_BASE_ACCESS.UPLOAD_FILE_OPTION.filter(item => roles.find(x => x === item)).length) ? (
                            <Tab eventKey="User File Upload" title="User File Upload">
                                <UserUpload />
                            </Tab>
                        ) : (null)
                    }

                    {
                        (ConfigData.ROLES_BASE_ACCESS.VIEW_ORG_FILE_UPLOAD_TAB.filter(item => roles.find(x => x === item)).length) ? (
                            <Tab eventKey="Organisation File Upload" title="Organisation File Upload">
                                <AdminUpload handleOrgFileProgressCall={handleOrgFileProgress} />
                            </Tab>
                        ) : (null)
                    }

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <button type="button"
                    className="btn btn-outline-danger"
                    onClick={() => handleModel(false)}
                    title='Close'>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}
export default FileUpload;
import { createContext, useContext } from "react";
// import { Roles } from '../useContext';

export const RolesContext = createContext(undefined);

export const useRolesContext = () => {
    const roles = useContext(RolesContext);

    if(roles === undefined){
        throw new Error('useRolesContext must be used waith a RolesContext');
    }

    return roles;
}
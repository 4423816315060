import ConfigData from '../../../../config.json';
import axios from "axios";
import '../styles.css';
import { useRolesContext } from "../../../../shared/context/roles-context";
import HashLoader from "react-spinners/HashLoader";
import React, { useEffect, useState, useRef } from "react";
import Toaster from '../../../../shared/toaster';
import Spinner from '../../../../shared/spinner';
import DirectoryTreeView from '../../../../shared/tree-view';

const AdminUpload = (props) => {
    const userId = sessionStorage.getItem('user_id');
    const roles = useRolesContext();
    const hiddenMultiFileInput = useRef(null);
    const [pageLoader, setPageLoader] = useState(false);
    const allwedFileExt = [...ConfigData.FILE_EXTENSION.UPLOAD_FILE_EXTENSION];
    const [isDisabled, setIsDisabled] = useState(true);
    const [listOfGroups, setListOfGroups] = useState([]);
    const [selectedGrp, setSelectedGrp] = useState(0);
    const [loader, setLoader] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [files, setFiles] = useState([])

    useEffect(() => {
        getGroups();
        groupsList();
        // getOrgUploadedFileList();
    }, []);

    const getGroups = () => {
        axios
            .get(process.env.REACT_APP_BASE_URL + `/user/[${roles}]/group`, {})
            .then((response) => {
                setListOfGroups(response.data);
            }).catch((error) => {
                console.error("ERROR ", error);
            });
    }

    const groupsList = () => {
        axios
            .get(process.env.REACT_APP_GET_GROUPS_URL + `/[${roles}]`, {})
            .then((response) => {
                const treeStructure = {
                    name: "",
                    children: response.data
                }
                setOrgData(treeStructure);
            }).catch((error) => {
                console.error("ERROR ", error);
            });
    };

    const handleMultiFileClick = (event) => {
        hiddenMultiFileInput.current.click(event);
        event.preventDefault();
    };

    const handleMultiFileChange = async (event) => {
        event.preventDefault();
        if (event.target.files.length <= 0)
            return

        var count = Number(0);
        const file_list = Array.prototype.slice.call(event.target.files);
        const fileCount = Number(file_list.length);

        file_list.map((file) => {
            let file_ext = file.name.split('.');
            let file_size = file.size / 1024;
            console.log('File Size ', file_size);
            if (allwedFileExt.includes(file_ext[file_ext.length - 1]) && file_size < ConfigData.MAX_FILE_SIZE) {
                setLoader(true);
                props.handleOrgFileProgressCall(true);
                const formData = new FormData();
                formData.append('file', file);
                formData.append('fileName', file.name);
                formData.append('user_id', userId);
                formData.append('group_id', selectedGrp);
                const response = axios
                    .post(process.env.REACT_APP_UPLOAD_FILE_ORG_URL,
                        formData
                    )
                    .then((response) => {
                        count++;
                        if (fileCount <= count) {
                            setLoader(false);
                            props.handleOrgFileProgressCall(false);
                            groupsList();
                            Toaster({ type: 'success', message: `File(s) uploaded successfully` });
                            event.target.value = null;
                        }
                    })
                    .catch((error) => {
                        count++;
                        if (fileCount <= count) {
                            setLoader(false);
                            props.handleOrgFileProgressCall(false);
                            event.target.value = null;
                        }
                        console.error('ERROR ', error.message);
                        Toaster({ type: 'error', message: error.message });
                    });
            } else {
                count++;
                event.target.value = null;
                Toaster({ type: 'error', message: ConfigData.MESSAGES.UPLOAD_FILE_ALERT_MSG });
                // Toaster({ type: 'error', message: `.${file_ext[file_ext.length - 1]} file extension not allowd to upload` });
            }
        });
    }

    const handleTreeDelete = (treeNode) => {
        const msg = `Do you want to delete ${treeNode.name} folder/file ?`;
        if (window.confirm(msg) == true) {
            setPageLoader(true);
            axios
                .delete(process.env.REACT_APP_BASE_URL + `/file/${treeNode.id}/delete_org_folder`, {})
                .then((response) => {
                    groupsList();
                    setPageLoader(false);
                    Toaster({ type: "success", message: `${treeNode.name} deleted successfully` });
                }).catch((error) => {
                    console.error("ERROR ", error);
                    setPageLoader(false);
                });
        }
    }

    const handleDropChange = (event) => {
        setSelectedGrp(event.target.value);
        if (event.target.value === "0") {
            return setIsDisabled(true)
        }
        setIsDisabled(false)
    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-6 section-divider'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <select className="form-select" id="fcategoryddl" onChange={handleDropChange} aria-label="Floating label select example" title="Category">
                                <option value="0">Select Category</option>
                                {listOfGroups.map((item, index) => (
                                    <option key={index} value={item.group_id}>{item.group_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-lg-6'>
                            {
                                (ConfigData.ROLES_BASE_ACCESS.ORG_FILE_UPLOAD_TAB_AND_BUTTON.filter(item => roles.find(x => x === item)).length) ? (
                                    <>
                                        <input
                                            type="file"
                                            onChange={(e) => (setFiles(e.target.files), handleMultiFileChange(e))}
                                            name="files[]"
                                            multiple
                                            ref={hiddenMultiFileInput}
                                            style={{ display: "none" }} // Make the file input element invisible
                                            accept={ConfigData.BROWSE_FILE_EXTENSION.UPLOAD_FILE}
                                        />
                                        <div>
                                            {loader ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <HashLoader color="#36d7b7" size={40} />
                                                </div>
                                            ) : (
                                                <button className='btn btn-primary'
                                                    onClick={handleMultiFileClick} disabled={isDisabled}>
                                                    Upload file
                                                </button>
                                            )}

                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                        <div className='col-lg-12'>
                            <div className="text-dark note-instruction">
                                <strong>Instruction</strong>
                                <ol>
                                    <li>
                                        <div dangerouslySetInnerHTML={{ __html: ConfigData.MESSAGES.ORG_UPLOAD_FILE_MODEL_INSTRUCTION_MSG }}></div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <strong>List of uploaded document folders/files</strong>
                    <div className="table-hr-scroll">
                        <DirectoryTreeView folder={orgData} handleDelete={handleTreeDelete} treeViewType="folders" />
                    </div>
                </div>
                <Spinner spinnerLoad={pageLoader} />
            </div>
        </>
    )
}
export default AdminUpload;
import React, { useEffect, useState ,useRef} from "react";
import { Modal } from 'react-bootstrap'
import axios from "axios";
import { FaTrash, FaRegFilePdf, FaFileWord, FaFile,FaDownload ,FaRegFileExcel,FaFolder, FaFolderOpen,FaSearchengin} from "react-icons/fa";
import DirectoryTreeView from '../shared/tree-view/index'
import { useRolesContext } from "../shared/context/roles-context";
import ConfigData from '../config.json';
import Toaster from '../shared/toaster';
import Spinner from '../shared/spinner';
import HashLoader from "react-spinners/HashLoader";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { saveAs } from 'file-saver';

import MyTemplate from '../Template/securityTemplate.xlsx';
import Accordion from 'react-bootstrap/Accordion';
import "./style.css";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { useMsal } from "@azure/msal-react";





const Security = (props) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isShow, invokeModal] = useState(false);
    const [resData, setResData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [draftData, setDraftData] = useState([]);
   
    const [files, setFiles] = useState([])
    const hiddenMultiFileInput = useRef(null);
    const allwedFileExt = [...ConfigData.FILE_EXTENSION.SECURITY_FILE_EXTENSION];
    const [loader, setLoader] = useState(false);
    const userId = sessionStorage.getItem('user_id');
    const [selectedGrp, setSelectedGrp] = useState(0);
    const [draftChecked, setDraftChecked] = useState(true);
    const [finalChecked, setFinalChecked] = useState(false);
   const [isDisabled, setIsDisabled] = useState(false);
  
    const selectedComponent = 'Security';
    const [isDraftAvailable, setIsDraftAvailable] = useState(false);
    const [isResAvailable, setIsResAvailable] = useState(false);
    const [isFinalAvailable, setIsFinalAvailable] = useState(false);
    const { instance, accounts } = useMsal();
    
    useEffect(() => {
  
    
        
    ConfigData.SELECTED_COMPONENT = 'Security';
        props.callSecurityModel.current = handleModel; 

        const dt = new Date().getTime();
        var today_number = new Date().valueOf();
        var today_number1 =  Math.random();

         console.log('today_number2', dt);
        console.log('today_number', today_number);
        console.log('today_number1',today_number1);
        
        draftList();
        responseList();
        finalList();   

          
    }, [])
        
       

    // const handleModel = (flag = true) => {
        const handleModel = (flag = true) => {
        return invokeModal(flag);
    }

    
  

    const responseList = () => { 
        const folder_name='response'
         axios
            .get(process.env.REACT_APP_DISPLAY_FOLDER_URL +  `/${folder_name} `)
            .then((response) => {
                console.log("response LIST", response.data);        
                const tmpResData = response.data;    
                if (response.data.length > 0) {
                    setIsResAvailable(true);
                }
            //     const treeStructure = {
            //         name: "",
            //         children: response.data
            //   };
            
                //     setResData(treeStructure);      
            
                
                 // Sort the children array
                const sortedData = [{
                    ...tmpResData[0],
                    children: sortChildrenByDate(tmpResData[0].children)
                }];

                console.log('response sortedData',sortedData);
                const treeStructure = {
                    name: "",
                    children: sortedData
                 };
              
                setResData(treeStructure)

            }).catch((error) => {
                console.error("ERROR ", error);
            });
    }

    const finalList = () => { 
        const folder_name='final'
         axios
            .get(process.env.REACT_APP_DISPLAY_FOLDER_URL +  `/${folder_name} `)
            .then((response) => {
                console.log("final LIST", response.data);        
                const tmpResData = response.data;           
                //setFinalData(tmpResData);    
                if (response.data.length > 0) {
                    setIsFinalAvailable(true);
                }
                 const sortedData = [{
                    ...tmpResData[0],
                    children: sortChildrenByDate(tmpResData[0].children)
                }];

                const treeStructure = {
                    name: "",
                    children: sortedData
                 };
            //     const treeStructure = {
            //         name: "",
            //         children: response.data
            //   };
            
                setFinalData(treeStructure); 
            }).catch((error) => {
                console.error("ERROR ", error);
            });
    }
    
    const draftList = () => { 
        const folder_name='draft'
         axios
            .get(process.env.REACT_APP_DISPLAY_FOLDER_URL +  `/${folder_name} `)
            .then((response) => {
                console.log("draft LIST", response.data);        
                const tmpResData = response.data;   
                if (response.data.length > 0) {
                    setIsDraftAvailable(true);
                }
 
                // Sort the children array
                const sortedData = [{
                    ...tmpResData[0],
                    children: sortChildrenByDate(tmpResData[0].children)
                }];

                const treeStructure = {
                    name: "",
                    children: sortedData
                 };
              
                setDraftData(treeStructure); 
            //   setDraftData1(...tmpResData[0].children);
                console.log('tmpResData.array', tmpResData[0].children);
                console.log('DraftData1', draftData['children'][0].children );            
                 })
            .catch((error) => {
                console.error("ERROR ", error);
            });
    }
   
  
//     const handleDownload = (treeNode) => {
//     console.log("treeNode", treeNode);
//     const formData = new FormData();
//     formData.append('fileName', treeNode.name);
//     console.log("formData", formData);

//     axios
//       .post(process.env.REACT_APP_DOWNLOAD_FROM_FOLDER_URL, { 'fileName': treeNode.name, 'folder_name':'response' }, { responseType: 'arraybuffer' })   
//       .then((response) => {
//         console.log("response", response);
//          const file = new Blob([response.data], {
//         type: "application/octet-stream",
//       });
//         saveAs(file, treeNode.name);
//         // Toaster({ type: "success", message: `${name} File downloaded successfully` });
//     });
// }
   
   const downloadFileTreeNode = (treeNode) => {
     console.log("treeNode", treeNode);
     handleDownload(treeNode)
  }

    const draftHandleChange = (e) => {
        const isChecked = e.target.checked;
        setDraftChecked(isChecked);
        setFinalChecked(false);
        };
    const finalHandleChange = (e) => {
       
         const isChecked1 = e.target.checked;
        setFinalChecked(isChecked1);
        setDraftChecked(false);
       
  };
 
 
    const handleMultiFileClick = (event) => {
        hiddenMultiFileInput.current.click(event);
       event.preventDefault();
    };
    const handleMultiFileChange = async (event) => {

       
        Toaster({ type: 'info', message: `File upload is in progress.` });
        
        setLoader(true);
        setIsDisabled(true);
       event.preventDefault();
        if (event.target.files.length <= 0)
            return

        var count = Number(0);
        const file_list = Array.prototype.slice.call(event.target.files);
        const fileCount = Number(file_list.length);

        file_list.map((file) => {
            let file_ext = file.name.split('.');
            let file_size = file.size / 1024;
            let getModifiedFileName = file.name.split('.')[0];
            
            console.log('getModifiedFileName ', getModifiedFileName);
            console.log('File Size ', file_size);
            if (allwedFileExt.includes(file_ext[file_ext.length - 1]) && file_size < ConfigData.MAX_FILE_SIZE) {
                // setLoader(true);
                //props.handleOrgFileProgressCall(true);      
            
                var today = new Date();
               
                const result1=  today.getFullYear() * 1e4 + (today.getMonth() + 1) * 100 + today.getDate() + ''; // "20211124"
                var time = new Date();
                const time1= ("0" + time.getHours()).slice(-2)   +  ("0" + time.getMinutes()).slice(-2) +   ("0" + time.getSeconds()).slice(-2);
                const dt=result1 + "_" +time1
                console.log("dt : " ,dt)
                const formData = new FormData();
                formData.append('file', file);
                formData.append('fileName', getModifiedFileName + "_" + dt + "."+file.name.split('.')[1]);
                formData.append('user_id', userId);
                formData.append('group_id', selectedGrp);
                var LLMID = sessionStorage.getItem('session_model');
                console.log("LLMID", LLMID);
                formData.append('llm_id', LLMID);
                console.log("formData", formData);

                const ragObject = JSON.parse(sessionStorage.getItem('ragObject'));
                console.log("security ragObject", ragObject);  
                
                // var getSelectedLLM = props.selectedLLM;
                // console.log("getSelectedLLM", getSelectedLLM);
                
               
              
                if (draftChecked==true)
                {
                     console.log("draftChecked", draftChecked);
                    const response = axios
                    .post(process.env.REACT_APP_UPLOAD_DRAFT_URL,
                        formData
                    )
                    .then((response) => {
                        // count++;
                        // if (fileCount <= count) {
                        setLoader(false);
                        // props.handleOrgFileProgressCall(false);
                        setIsDisabled(false);
                        draftList();
                        Toaster({ type: 'success', message: `Questionary file has been successfully uploaded.` });
                        Toaster({ type: 'info', message: `Email notification will be sent once the response file is generated.` });
                        responseList();
                        finalList();
                        // Toaster({ type: 'success', message: `File uploaded successfully in Response Folder` });                        
                        // onLoadData();
                        event.target.value=null;
                            
                        // }
                    })
                    .catch((error) => {
                        // count++;
                        // if (fileCount <= count) {
                        setLoader(false);
                          setIsDisabled(false);
                           // props.handleOrgFileProgressCall(false);
                            event.target.value = null;
                        // }
                        console.error('ERROR ', error.message);
                        Toaster({ type: 'error', message: error.message });
                    });
                
                 }

                 if (finalChecked==true)
                 {
                     
                     console.log('finalChecked',finalChecked);
                    const response = axios
                    .post(process.env.REACT_APP_UPLOAD_FINAL_URL,
                        formData
                    )
                    .then((response) => {
                        // count++;
                        // if (fileCount <= count) {
                             setLoader(false);
                    // props.handleOrgFileProgressCall(false);
                        setIsDisabled(false);
                            draftList();
                            responseList();
                            finalList();
                            Toaster({ type: 'success', message: `File uploaded successfully in Final folder` });
                             event.target.value=null;                          
                        // }
                    })
                    .catch((error) => {
                        // count++;
                        // if (fileCount <= count) {
                        setLoader(false);
                          setIsDisabled(false);
                           // props.handleOrgFileProgressCall(false);
                            event.target.value = null;
                        // }
                        console.error('ERROR ', error.message);
                        Toaster({ type: 'error', message: error.message });
                    });
                
                 }
                
                
            } else {
                // count++;
                event.target.value = null;
                Toaster({ type: 'error', message: ConfigData.MESSAGES.UPLOAD_FILE_ALERT_MSG });
                setIsDisabled(false);
                setLoader(false);
                // Toaster({ type: 'error', message: `.${file_ext[file_ext.length - 1]} file extension not allowd to upload` });
            }
        });
    }

     const handlePreview = async (name, folder_name) => {
        console.log('handlePreview');
            axios
               .post(process.env.REACT_APP_DOWNLOAD_FROM_FOLDER_URL, { 'fileName': name, 'folder_name':folder_name }, { responseType: 'arraybuffer' })   
                .then(async (response) => {
                    console.log("response", response);
                    const file = new Blob([response.data], {
                    type: "application/octet-stream",
                    });
                    

                      const accessTokenResponse = await instance.acquireTokenSilent({
                            scopes: ["user.read"], // Add the required scopes
                            account: accounts[0],
                            });      
           
                        console.log('security accessTokenResponse',accessTokenResponse);
                        const accessToken = accessTokenResponse.accessToken;
                        console.log('security accessToken',accessToken);
                        sessionStorage.setItem("graphAccessToken", accessToken);
        
                        const response1 = await axios.get('https://graph.microsoft.com/v1.0/me', {
                        headers: {
                        Authorization: `Bearer ${accessTokenResponse.accessToken}`,
                        },
                        });
        
                        console.log('graph response', response1)
                    
                   
                    console.log('accessTokenResponse.accessToken', accessTokenResponse.accessToken)
                    console.log('filename',name);
                    const response2 = await axios.put('https://graph.microsoft.com/v1.0/me/drive/special/Documents:/' +name +':/content', 
                    file,
                        {
                            headers: {
                                Authorization: `Bearer ${accessTokenResponse.accessToken}`,
                                'Content-Type': 'application/octet-stream'
                            }
                        }
                    );

                    console.log('graph response2', response2);


                    try {
                        
                    } catch (error) {
                        
                    }
                    if ((response2.status == 201) || (response2.status == 200)) {
                        const onedrive_url = response2.data['webUrl'];
                        console.log('onedrive_url', onedrive_url);
                        
                        if (onedrive_url !== '')
                        {
                           
                            window.open( onedrive_url);
        
   
                        }
                    }
                    else
                    {
                    //       console.log('Error uploading to OneDrive:', response.json())
                    //    const onedrive_url = "";
                        }
                    

            // Toaster({ type: "success", message: `${name} File downloaded successfully` });
                    }).catch((error) => {
                        console.error("ERROR ", error);
                        //setPageLoader(false);
                    });
        
       

    }

    const handleDownload = (name, folder_name) => {
        setIsDownloading(true);
            axios
               .post(process.env.REACT_APP_DOWNLOAD_FROM_FOLDER_URL, { 'fileName': name, 'folder_name':folder_name }, { responseType: 'arraybuffer' })   
                .then((response) => {
                    console.log("response", response);
                    const file = new Blob([response.data], {
                    type: "application/octet-stream",
                });
                    saveAs(file, name);
                    Toaster({ type: "success", message: `${name} File downloaded successfully` });
                     setIsDownloading(false);
                    }).catch((error) => {
                        console.error("ERROR ", error);
                        setIsDownloading(false);
                        //setPageLoader(false);
                    });
         
    }

    // Function to extract datetime from filename
    const extractDateFromFilename = (filename) => {
        const dateFormat1 = /(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})\.xlsx$/;
        const dateFormat2 = /(\d{4})_(\d{1,2})_(\d{1,2})_(\d{1,2})_(\d{1,2})_(\d{1,2})\.xlsx$/;
        const dateFormat3 = /(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})\_response.xlsx$/;
        const dateFormat4 = /(\d{4})_(\d{1,2})_(\d{1,2})_(\d{1,2})_(\d{1,2})_(\d{1,2})\_response.xlsx$/;

       
        let match = filename.match(dateFormat1);
        if (match) {
            const [, year, month, day, hour, minute, second] = match;
            return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
        }

        match = filename.match(dateFormat2);
        if (match) {
            const [, year, month, day, hour, minute, second] = match;
            return new Date(year, month - 1, day, hour, minute, second);
        }

           match = filename.match(dateFormat3);
        if (match) {
            const [, year, month, day, hour, minute, second] = match;
            return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
        }

        match = filename.match(dateFormat4);
        if (match) {
            const [, year, month, day, hour, minute, second] = match;
            return new Date(year, month - 1, day, hour, minute, second);
        }


        return null; // Return null if date format is not recognized
    };  
    
      const sortChildrenByDate = (children) => {
        return children.sort((a, b) => {
            const dateA = extractDateFromFilename(a.name);
            const dateB = extractDateFromFilename(b.name);
            return (dateB - dateA);
        });
                };

    

    return (
        <Modal show={isShow} size='xl' scrollable='true' contentClassName="modal-height">
            <Modal.Header>
                <Modal.Title>Security Assessment Processor</Modal.Title>
                 <button type="button"
                    className="btn-close"
                    onClick={() => handleModel(false)}
                    title='Close'
                    aria-label="Close">
                </button>
            </Modal.Header>
            <Modal.Body>
                
   <div className='row'>
                    <div className='col-lg-6 section-divider'>
                        <strong>Instructions</strong>
                        <div className="text-dark">         
                            <ol>
                                <ul><li><strong>1. </strong>Only .xlsx files can be processed. Download this <a href={MyTemplate} download="securityTemplate.xlsx"> Template </a></li></ul>
                                <li dangerouslySetInnerHTML={{ __html: ConfigData.MESSAGES.SECURITY_UPLOAD_FILE_INSTEUCTION_MSG }}></li>
                            </ol>
                        </div>
                       
                        <div class="radio-group">
                             <label>
                                <input type="radio" name="orderType" value = "Draft" defaultChecked  onChange={draftHandleChange}/>
                                { } To Be Processed                              
                            </label>

                            <label>
                                <input type="radio" name="orderType" value = "Final" onChange={finalHandleChange}/>
                                { }  Final  
                            </label>
                        </div>                     

                                         <input
                                                type="file"
                                                onChange={(e) => (setFiles(e.target.files), handleMultiFileChange(e))}
                                                name="files[]"
                                                
                                                ref={hiddenMultiFileInput}
                                                style={{ display: "none" }} // Make the file input element invisible
                                                accept={ConfigData.BROWSE_FILE_EXTENSION.UPLOAD_SECURITY_FILE}
                                            />

                        <div>
                            
                            <div>
                                            {loader ? (
                                                <div className="flex flex-col items-center justify-center">
                                                    <HashLoader color="#36d7b7" size={40} />
                                                </div>
                                            ) : (
                                                <button className='btn btn-primary'
                                                    onClick={handleMultiFileClick} disabled={isDisabled}>
                                                    Upload file
                                                </button>
                                            )}

                                        </div>
                            
                            {/* <button className='btn btn-primary'
                                onClick={handleMultiFileClick} 
                                >
                                Upload file
                            </button> */}
                        </div>
                    
                    
                        

                        
                    </div>

            <div className='col-lg-6'>
              <strong>Security Assessment Folders</strong>
                {/* <div >
                        <DirectoryTreeView folder={draftData}   treeViewType="folders" />
              </div>
               <div >
                        <DirectoryTreeView folder={resData}  treeViewType="folders" />
              </div>
               <div >
                        <DirectoryTreeView folder={finalData}   treeViewType="folders" />
               </div> */}
                 <div className="table-responsive table-hr-scroll main-table">
                    <Accordion >
                         <Accordion.Item eventKey="0">
                            <Accordion.Header>To Be Processed</Accordion.Header>
                            <Accordion.Body>
                                <table>
                                        <tbody>
                                            {
                                                isDraftAvailable === true ?
                                                        (draftData['children'][0].children.map((item, index) => (
                                                        <tr key={index}>
                                                            <td colspan='10' className='table-row-width cursor-pointer'  title={item.name}>
                                                                <FileIcon filename={item.name} />
                                                                <div className='td-text-wrap' onClick={() => handlePreview(item.name, 'draft')}>{item.name}</div>
                                                                </td>
                                                               
                                                               
                                                             
                                                                <td ><FaDownload color="gray" onClick={() => handleDownload(item.name, 'draft')} hidden={isDownloading} /></td>
                                                                 {/* <td><FaSearchengin color="gray" onClick={() => handlePreview(item.name, 'draft')} hidden={isDownloading} /></td> */}
                                                       
                                                            </tr>
                                                    ))
                                                    ) :null
                                            
         
                        }
                                    </tbody>
                                 </table>
                            </Accordion.Body>
                        </Accordion.Item>
                         <Accordion.Item eventKey="1">
                            <Accordion.Header>Response</Accordion.Header>
                            <Accordion.Body>
                                <table>
                                    <tbody>
                                        {
                                            isResAvailable===true?(
                                                resData['children'][0].children.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className='table-row-width cursor-pointer'  title={item.name}>
                                                                <FileIcon filename={item.name} />
                                                                <div className='td-text-wrap' onClick={() => handlePreview(item.name, 'response')}>{item.name}</div>
                                                            </td>
                                                            <td> <FaDownload color="gray"  onClick={() => handleDownload(item.name,'response')}  hidden={isDownloading} /></td>
                                                        {/* <td><FaSearchengin color="gray" onClick={() => handlePreview(item.name, 'response')} hidden={isDownloading} /></td> */}
                                                    </tr>
                                                    ))):null
                                        }
                                    </tbody>
                                 </table>
                            </Accordion.Body>
                        </Accordion.Item>
                         <Accordion.Item eventKey="2">
                            <Accordion.Header>Final</Accordion.Header>
                            <Accordion.Body>
                                <table>
                                    <tbody>
                                         {
                                            isFinalAvailable===true?(
                                                finalData['children'][0].children.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='table-row-width cursor-pointer'  title={item.name}>                                             
                                                             <FileIcon filename={item.name} />
                                                            <div className='td-text-wrap' onClick={() => handlePreview(item.name, 'final')}>{item.name}</div>                                         
                                                        </td>
                                                        <td> <FaDownload color="gray"  onClick={() => handleDownload(item.name,'final')}  hidden={isDownloading}/></td>
                                                    {/* <td><FaSearchengin color="gray" onClick={() => handlePreview(item.name, 'final')} hidden={isDownloading} /></td> */}
                                                    </tr>
                                                ))
                                            ):null
                                        }
                                    </tbody>
                                 </table>
                            </Accordion.Body>
                        </Accordion.Item>
                     </Accordion>
                </div>
                    
                     </div>
                
               
                </div>

              </Modal.Body>

            
            
        </Modal>
    )

}
const FileIcon = ({ filename }) => {
   // const extension = filename.slice(filename.lastIndexOf(".") + 1);
    const extension = 'xlsx';
    switch (extension) {
        case "xlsx":
            return <FaRegFileExcel color="#07bc0c" className="icon" size={20} />;       
        case "pdf":
            return <FaRegFilePdf color="#FF0000" className="icon" size={20} />;
        case "doc":
            return <FaFileWord color="#01A6F0" className="icon" size={20} />;
        case "docx":
            return <FaFileWord color="#01A6F0" className="icon" size={20} />;
        default:
            return <FaFile color="#D5CE58" className="icon" size={20} />;
    }
};

const FolderIcon = ({ isOpen }) =>
  isOpen ? (
    <FaFolderOpen color="#FFEA00" className="icon" fill="#FFEA00" size={25} />
  ) : (
    <FaFolder color="#FEBE10" className="icon" fill="#FEBE10" size={25} />
  );

export default Security;

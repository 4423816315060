import ConfigData from '../../../../config.json';
import axios from "axios";
import '../styles.css';
import React, { useEffect, useState, useRef } from "react";
import HashLoader from "react-spinners/HashLoader";
import { FaTrash, FaRegFilePdf, FaFileWord, FaFile } from "react-icons/fa";
import Toaster from '../../../../shared/toaster';
import Spinner from '../../../../shared/spinner';

const UserUpload = (props) => {
    const userId = sessionStorage.getItem('user_id');
    const hiddenFileInput = useRef(null);
    const [pageLoader, setPageLoader] = useState(false);
    const allwedFileExt = [...ConfigData.FILE_EXTENSION.UPLOAD_FILE_EXTENSION];
    const [documentList, setDocumentList] = useState([]);
    const [fileCount, setfileCount] = useState(Number(0));
    const [listLoading, setListLoading] = useState(Boolean(true));

    useEffect(() => {
        getUploadedFileList();
    }, []);

    const handleClick = (event) => {
        hiddenFileInput.current.click(event);
        event.preventDefault();
    };

    // Multiple prompt upload functionality
    const handleChange = async (event) => {
        const fileExtCheck = event.target.value.split('.');
        const file_size = event.target.files[0].size / 1024;

        if (allwedFileExt.includes(fileExtCheck[fileExtCheck.length - 1]) && file_size < ConfigData.MAX_FILE_SIZE) {
            handleFileUploadCall(event);
        } else {
            alert(ConfigData.MESSAGES.UPLOAD_FILE_ALERT_MSG);
            event.target.value = null;
            return;
        }
    };

    const handleFileUploadCall = (event) => {
        event.preventDefault();
        if (fileCount < ConfigData.USER_FILE_UPLOAD_LIMIT) {
            setPageLoader(true);
            const file = event.target.files[0];
            const file_name = file.name;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file_name);
            formData.append('user_id', userId);
            const response = axios
                .post(process.env.REACT_APP_UPLOAD_FILE_USER_URL,
                    formData
                )
                .then((response) => {
                    // props.handleUserFileUploadCallback(file_name);
                    setPageLoader(false);
                    getUploadedFileList();
                    Toaster({ type: 'success', message: `${file_name} uploaded successfully` });
                    // handleModel(false);
                })
                .catch((error) => {
                    setPageLoader(false);
                    console.error('ERROR ', error.message);
                    Toaster({ type: 'error', message: error.message });
                    // handleModel(false);
                });
        } else {
            alert(ConfigData.USER_FILE_UPLOAD_LIMIT_MSG);
        }
        event.target.value = null;
    }

    const getUploadedFileList = () => {
        setDocumentList([]);
        setListLoading(true);
        axios
            .get(process.env.REACT_APP_BASE_URL + `/user/${userId}/files`, {})
            .then((response) => {
                setListLoading(false);
                setDocumentList(response.data.reverse());
                setfileCount(response.data.length);
            }).catch((error) => {
                console.error("ERROR ", error);
                setListLoading(false);
            });
    }

    const handleDelete = (id, name) => {
        const delMsg = `Do you want to delete ${name} ?`;
        if (window.confirm(delMsg) == true) {
            setPageLoader(true);
            axios
                .delete(process.env.REACT_APP_BASE_URL + `/file/${id}/delete`, {})
                .then((response) => {
                    getUploadedFileList();
                    setPageLoader(false);
                    Toaster({ type: "success", message: `${name} File deleted successfully` });
                }).catch((error) => {
                    console.error("ERROR ", error);
                    setPageLoader(false);
                });
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-6 section-divider'>
                    <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        style={{ display: "none" }} // Make the file input element invisible
                        accept={ConfigData.BROWSE_FILE_EXTENSION.UPLOAD_FILE}
                    />
                    <div>
                        <button className='btn btn-primary'
                            onClick={handleClick} >
                            Upload file
                        </button>
                    </div>
                    <div className="text-dark note-instruction">
                        <strong>Instruction</strong>
                        <ol>
                            <li>
                                <div dangerouslySetInnerHTML={{ __html: ConfigData.MESSAGES.UPLOAD_FILE_MODEL_INSTRUCTION_MSG }}></div>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <strong>List of uploaded documents</strong>
                    <div className="table-responsive table-hr-scroll main-table">
                        {
                            listLoading ? (
                                <div className="flex flex-col items-center justify-center loading">
                                    <HashLoader color="#36d7b7" size={40} />
                                </div>
                            ) : (
                                <table>
                                    <tbody>
                                        {
                                            documentList.map((name, index) => (
                                                <tr key={index}>
                                                    <td className='table-row-width'>
                                                        <FileIcon filename={name.file_metadata_name} />
                                                        <div className='td-text-wrap'>{name.file_metadata_name}</div>
                                                    </td>
                                                    <td> <FaTrash color="gray" onClick={() => handleDelete(name.file_metadata_id, name.file_metadata_name)} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
                <Spinner spinnerLoad={pageLoader} />
            </div>
        </>
    )
};
const FileIcon = ({ filename }) => {
    const extension = filename.slice(filename.lastIndexOf(".") + 1);
    switch (extension) {
        case "pdf":
            return <FaRegFilePdf color="#FF0000" className="icon" size={20} />;
        case "doc":
            return <FaFileWord color="#01A6F0" className="icon" size={20} />;
        case "docx":
            return <FaFileWord color="#01A6F0" className="icon" size={20} />;
        default:
            return <FaFile color="#D5CE58" className="icon" size={20} />;
    }
};
export default UserUpload;